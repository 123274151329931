var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"mb-6",attrs:{"dark":"","flat":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Change Password")])],1),_c('v-card-text',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-form',[_c('ValidationProvider',{attrs:{"name":"non_field_errors","vid":"non_field_errors"}},[(errors.non_field_errors && errors.non_field_errors.length)?_c('v-alert',{attrs:{"text":"","dense":"","color":"error","icon":"mdi-alert-octagon-outline","border":"left"}},_vm._l((errors.non_field_errors),function(error){return _c('div',{key:error},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-row',{},[_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"New password","vid":"new_password1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"New password*","error-messages":errors,"append-icon":_vm.new_password1_visible ? 'visibility' : 'visibility_off',"type":_vm.new_password1_visible ? 'text' : 'password',"required":""},on:{"click:append":function () { return (_vm.new_password1_visible = !_vm.new_password1_visible); }},model:{value:(_vm.password.new_password1),callback:function ($$v) {_vm.$set(_vm.password, "new_password1", $$v)},expression:"password.new_password1"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_c('ul',[_c('li',[_vm._v("Your password can’t be too similar to your other personal information.")]),_c('li',[_vm._v("Your password must contain at least 8 characters.")]),_c('li',[_vm._v("Your password can’t be a commonly used password.")]),_c('li',[_vm._v("Your password can’t be entirely numeric.")])])])])],1),_c('v-col',{},[_c('ValidationProvider',{attrs:{"name":"New password confirmation","vid":"new_password2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"New password confirmation*","error-messages":errors,"append-icon":_vm.new_password2_visible ? 'visibility' : 'visibility_off',"type":_vm.new_password2_visible ? 'text' : 'password',"required":""},on:{"click:append":function () { return (_vm.new_password2_visible = !_vm.new_password2_visible); }},model:{value:(_vm.password.new_password2),callback:function ($$v) {_vm.$set(_vm.password, "new_password2", $$v)},expression:"password.new_password2"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":"","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:" px-6",attrs:{"color":"secondary","dark":""},on:{"click":_vm.updatePassword}},[_vm._v(" Update ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }