var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',{staticClass:"elevation-10"},[_c('v-toolbar',{attrs:{"dark":"","flat":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Sign up")])],1),_c('v-card-text',[(_vm.authenticated || _vm.detail)?_c('v-alert',{attrs:{"text":"","border":"left","colored-border":"","type":"info"}},[(_vm.authenticated)?_c('div',[_vm._v("You are already logged in! You don't need to register.")]):_c('div',[_vm._v(_vm._s(_vm.detail))])]):_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ValidationProvider',{attrs:{"name":"non_field_errors","vid":"non_field_errors"}},[(errors.non_field_errors && errors.non_field_errors.length)?_c('v-alert',{attrs:{"text":"","dense":"","color":"error","icon":"mdi-alert-octagon-outline","border":"left"}},_vm._l((errors.non_field_errors),function(error){return _c('div',{key:error},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-form',[_c('ValidationProvider',{attrs:{"name":"username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"person","label":"Username","error-messages":errors,"type":"text","required":""},model:{value:(_vm.subscription.username),callback:function ($$v) {_vm.$set(_vm.subscription, "username", $$v)},expression:"subscription.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"email","name":"email","label":"Email address","id":"email","error-messages":errors,"required":""},model:{value:(_vm.subscription.email),callback:function ($$v) {_vm.$set(_vm.subscription, "email", $$v)},expression:"subscription.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","vid":"password1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"lock","name":"password1","label":"Password","id":"password1","error-messages":errors,"append-icon":_vm.password1_visible ? 'visibility' : 'visibility_off',"type":_vm.password1_visible ? 'text' : 'password',"required":""},on:{"click:append":function () { return (_vm.password1_visible = !_vm.password1_visible); }},model:{value:(_vm.subscription.password1),callback:function ($$v) {_vm.$set(_vm.subscription, "password1", $$v)},expression:"subscription.password1"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","vid":"password2","rules":"required|confirmed:password1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"lock","name":"password2","label":"Password (confirm)","id":"password2","error-messages":errors,"append-icon":_vm.password2_visible ? 'visibility' : 'visibility_off',"type":_vm.password2_visible ? 'text' : 'password',"required":""},on:{"click:append":function () { return (_vm.password2_visible = !_vm.password2_visible); }},model:{value:(_vm.subscription.password2),callback:function ($$v) {_vm.$set(_vm.subscription, "password2", $$v)},expression:"subscription.password2"}})]}}],null,true)})],1)]}}])})],1),(!_vm.authenticated)?_c('v-card-actions',{staticClass:"mr-2 pb-4"},[_c('v-btn',{attrs:{"color":"darkgrey","plain":"","text":"","to":{ name: 'auth_login'}}},[_vm._v(" sign in? ")]),_c('v-spacer'),(!_vm.detail)?_c('v-btn',{staticClass:"px-4",attrs:{"dark":"","color":"secondary"},on:{"click":_vm.register}},[_vm._v(" Register ")]):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }