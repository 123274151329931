var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"mb-6",attrs:{"dark":"","flat":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Change Profile")])],1),_c('v-card-text',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-form',[_c('ValidationProvider',{attrs:{"name":"non_field_errors","vid":"non_field_errors"}},[(errors.non_field_errors && errors.non_field_errors.length)?_c('v-alert',{attrs:{"text":"","dense":"","color":"error","icon":"mdi-alert-octagon-outline","border":"left"}},_vm._l((errors.non_field_errors),function(error){return _c('div',{key:error},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-row',{},[_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Username","vid":"username","rules":"max:150|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Username*","error-messages":errors,"type":"text","required":"","disabled":""},model:{value:(_vm.profile.username),callback:function ($$v) {_vm.$set(_vm.profile, "username", $$v)},expression:"profile.username"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.")])])],1)],1),_c('v-row',{},[_c('v-col',{},[_c('ValidationProvider',{attrs:{"name":"First name","vid":"first_name","rules":"max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First name","error-messages":errors,"type":"text"},model:{value:(_vm.profile.first_name),callback:function ($$v) {_vm.$set(_vm.profile, "first_name", $$v)},expression:"profile.first_name"}})]}}],null,true)})],1),_c('v-col',{},[_c('ValidationProvider',{attrs:{"name":"Last name","vid":"last_name","rules":"max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last name","error-messages":errors,"type":"text"},model:{value:(_vm.profile.last_name),callback:function ($$v) {_vm.$set(_vm.profile, "last_name", $$v)},expression:"profile.last_name"}})]}}],null,true)})],1)],1),_c('v-row',{},[_c('v-col',{},[_c('ValidationProvider',{attrs:{"name":"Email address","vid":"email","rules":"email|max:254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email address","error-messages":errors,"type":"email"},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":"","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:" px-6",attrs:{"color":"secondary","dark":""},on:{"click":_vm.updateProfile}},[_vm._v(" Update ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }