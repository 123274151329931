var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-10"},[_c('v-toolbar',{staticClass:"mb-6",attrs:{"dark":"","flat":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s({"S": "Server", "C": "Client", "O": "OCSP"}[this.certtype])+" certificate ")])],1),_c('v-card-text',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-form',[_c('ValidationProvider',{attrs:{"name":"non_field_errors","vid":"non_field_errors"}},[(errors.non_field_errors && errors.non_field_errors.length)?_c('v-alert',{attrs:{"text":"","dense":"","color":"error","icon":"mdi-alert-octagon-outline","border":"left"}},_vm._l((errors.non_field_errors),function(error){return _c('div',{key:error},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-row',{},[_c('v-col',{},[_c('v-card',{staticClass:" ",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Distinguished Name")]),_c('v-card-text',[_c('v-row',{staticClass:"mr-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":"","text":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset Form ")]),_c('v-btn',{attrs:{"color":"secondary","dark":""},on:{"click":_vm.setParentData}},[_vm._v(" Copy from Intermediate ")])],1),_c('v-row',{},[_c('v-col',{attrs:{"md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Common Name","vid":"dn__commonName","rules":"max:64|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Common Name*","error-messages":errors,"type":"text","required":""},model:{value:(_vm.certificate.dn.commonName),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "commonName", $$v)},expression:"certificate.dn.commonName"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The fully qualified domain name (FQDN) of your server. This must match exactly what you type in your web browser or you will receive a name mismatch error.")])])],1),_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Expires at","vid":"expires_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Expires at*","error-messages":errors,"type":"date","required":""},model:{value:(_vm.certificate.expires_at),callback:function ($$v) {_vm.$set(_vm.certificate, "expires_at", $$v)},expression:"certificate.expires_at"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Select the date that the certificate will expire: for root typically 20 years, for intermediate 10 years for other types 1 year.")])])],1)],1),_c('v-row',{},[_c('v-col',{attrs:{"md12":"","xs12":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"SubjectAltNames","vid":"dn__subjectAltNames","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"error-messages":errors,"label":"SubjectAltNames","append-icon":"","chips":"","deletable-chips":"","multiple":""},model:{value:(_vm.certificate.dn.subjectAltNames),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "subjectAltNames", $$v)},expression:"certificate.dn.subjectAltNames"}})]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("subjectAltName list, i.e. dns names for server certs and email adresses for client certs. (separate by comma)")])])],1)],1),_c('v-row',{},[_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Organization Name","vid":"dn__organizationName","rules":"max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Organization Name","error-messages":errors,"type":"text"},model:{value:(_vm.certificate.dn.organizationName),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "organizationName", $$v)},expression:"certificate.dn.organizationName"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The legal name of your organization. This should not be abbreviated and should include suffixes such as Inc, Corp, or LLC.")])])],1),_c('v-col',{attrs:{"md6":"","xs12":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Organization Unit Name","vid":"dn__organizationalUnitName","rules":"max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Organization Unit Name","error-messages":errors,"type":"text"},model:{value:(_vm.certificate.dn.organizationalUnitName),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "organizationalUnitName", $$v)},expression:"certificate.dn.organizationalUnitName"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The division of your organization handling the certificate.")])])],1)],1),_c('v-row',{},[_c('v-col',{attrs:{"md12":"","xs12":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Email Address","vid":"dn__emailAddress","rules":"email|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Email Address","error-messages":errors,"type":"email"},model:{value:(_vm.certificate.dn.emailAddress),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "emailAddress", $$v)},expression:"certificate.dn.emailAddress"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The email address to contact your organization.")])])],1)],1),_c('v-row',{},[_c('v-col',{attrs:{"md":"5"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"State or Province Name","vid":"dn__stateOrProvinceName","rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"State or Province Name","error-messages":errors,"type":"text"},model:{value:(_vm.certificate.dn.stateOrProvinceName),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "stateOrProvinceName", $$v)},expression:"certificate.dn.stateOrProvinceName"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The state/region where your organization is located. This shouldn't be abbreviated. (1–128 characters)")])])],1),_c('v-col',{attrs:{"md":"5"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Locality Name","vid":"dn__localityName","rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Locality Name","error-messages":errors,"type":"text"},model:{value:(_vm.certificate.dn.localityName),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "localityName", $$v)},expression:"certificate.dn.localityName"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The city where your organization is located. (1–128 characters)")])])],1),_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Country","vid":"dn__countryName","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.formdata_certificate_dn_countryName_values,"label":"Country"},model:{value:(_vm.certificate.dn.countryName),callback:function ($$v) {_vm.$set(_vm.certificate.dn, "countryName", $$v)},expression:"certificate.dn.countryName"}})]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The two-character country name in ISO 3166 format.")])])],1)],1)],1)],1)],1)],1),_c('v-row',{},[_c('v-col',{},[_c('v-card',{staticClass:" ",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Certificate")]),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Name","vid":"name","rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Name","error-messages":errors,"type":"text"},model:{value:(_vm.certificate.name),callback:function ($$v) {_vm.$set(_vm.certificate, "name", $$v)},expression:"certificate.name"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Name of your key, if not set will be equal to your CommonName.")])]),_c('v-row',{},[_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Passphrase","vid":"passphrase_out","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Passphrase","error-messages":errors,"append-icon":_vm.passphrase_out_visible ? 'visibility' : 'visibility_off',"type":_vm.passphrase_out_visible ? 'text' : 'password'},on:{"click:append":function () { return (_vm.passphrase_out_visible = !_vm.passphrase_out_visible); }},model:{value:(_vm.certificate.passphrase_out),callback:function ($$v) {_vm.$set(_vm.certificate, "passphrase_out", $$v)},expression:"certificate.passphrase_out"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Passphrase for protecting the key of your new certificate.")])])],1),_c('v-col',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Passphrase confirmation","vid":"passphrase_out_confirmation","rules":"confirmed:passphrase_out"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Passphrase confirmation","error-messages":errors,"append-icon":_vm.passphrase_out_confirmation_visible ? 'visibility' : 'visibility_off',"type":_vm.passphrase_out_confirmation_visible ? 'text' : 'password'},on:{"click:append":function () { return (_vm.passphrase_out_confirmation_visible = !_vm.passphrase_out_confirmation_visible); }},model:{value:(_vm.certificate.passphrase_out_confirmation),callback:function ($$v) {_vm.$set(_vm.certificate, "passphrase_out_confirmation", $$v)},expression:"certificate.passphrase_out_confirmation"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Enter the same passphrase as before, for verification.")])])],1)],1)],1)],1)],1)],1),_c('v-row',{},[_c('v-col',{},[_c('v-card',{staticClass:" ",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Signing credentials")]),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Passphrase issuer","vid":"passphrase_issuer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Passphrase issuer*","error-messages":errors,"append-icon":_vm.passphrase_issuer_visible ? 'visibility' : 'visibility_off',"type":_vm.passphrase_issuer_visible ? 'text' : 'password',"required":""},on:{"click:append":function () { return (_vm.passphrase_issuer_visible = !_vm.passphrase_issuer_visible); }},model:{value:(_vm.certificate.passphrase_issuer),callback:function ($$v) {_vm.$set(_vm.certificate, "passphrase_issuer", $$v)},expression:"certificate.passphrase_issuer"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("The passphrase for unlocking your signing key.")])])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":"","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:" px-6",attrs:{"color":"secondary","dark":""},on:{"click":_vm.onCreateCertificate}},[_vm._v(" Create ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }